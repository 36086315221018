import I18n from 'i18n';
import Snack from '@effectivastudio/snackbar';

$(document)
  .on('ajax:beforeSend', '.js-hide-banner', (e) => {
    e.target.closest('.banner').classList.add('d-none');
  })
  .on('ajax:success', '.js-hide-banner', () => {
    Snack({
      text: I18n.t('banner.snack_hide_banner.success'),
      actionText: '<i class="icon-close"></i>',
    });
  })
  .on('ajax:error', '.js-hide-banner', (e) => {
    e.target.closest('.banner').classList.remove('d-none');
    Snack({
      text: I18n.t('banner.snack_hide_banner.failure'),
      actionText: '<i class="icon-close"></i>',
    });
  });
