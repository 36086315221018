import AutoHideHeader from '@effectivastudio/auto_hide_header';

document.addEventListener('turbolinks:load', () => {
  new AutoHideHeader('.js-auto-hide-header', {
    transitionOffset: 1,
    disableAboveViewportWidth: 768,
  });
});

document.addEventListener('turbolinks:before-cache', () => {
  AutoHideHeader.destroyAll();
});
